import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { HeaderAuraCard } from "../components/Headers/HeaderAuracard"
import { Banner } from "../components/Banners/HalvaCardVariation"
import { Category } from "../components/Category"
import { LetoForm } from "../components/NewForms/LetoForm"
import { NewFooter } from "../components/NewFooter"

import type { PageData } from "../interfaces/pageProps"
import type { City } from "../components/FormikForm/fields/CityField"

import { getPageData } from "../helpers/getPageData"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"

const FORM_TITLE =
  "Заполните форму и получите карту курьером или у сотрудника Совкомбанка в ТРК «ЛЕТО»"

const CITY: City = {
  value: "г. Санкт-Петербург",
  label: "Санкт-Петербург",
  region: "Санкт-Петербург и Ленинградская область",
}

const BENEFITS_TITLE = (
  <>
    Преимущества <br /> Специальной карты Халва
  </>
)

const titleBanner = (
  <>
    <span style={{ color: "rgba(255, 78, 80, 1)" }}>Специальная карта Халва —</span> одна карта для
    всего
  </>
)

const descBanner = (
  <>
    Спец.условия по Халве на покупки <br />в ТРЦ «Лето». Оформите здесь!
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { title, description, ligal, noIndex } = getPageData(data)

  return (
    <Layout description={description} title={title} noHeader noFooter noIndex={noIndex}>
      <HeaderAuraCard />
      <Banner
        variantImg="leto"
        variantStyle="leto"
        title={titleBanner}
        description={descBanner}
        orderNum="1"
      />
      <FourAdvantagesPoints
        withTitle
        indent={false}
        variant="special"
        title={BENEFITS_TITLE}
        orderNum="2"
      />
      <Category orderNum="3" />
      <LetoForm title={FORM_TITLE} default_city={CITY} orderNum="4" />
      <NewFooter ligal={ligal} isRedButton orderNum="5" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-special/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
    pageDataJson(pageName: { eq: "halva-special" }) {
      title
      description
    }
  }
`
